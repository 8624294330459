import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/* Layout */
import Layout from '@/pages/baseLayout'

export const constantRoutes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'index',
                name: 'index',
                component: () => import('@/pages/homePage'),
            },
            {
                path: 'login',
                name: 'login',
                component: () => import('@/pages/loginPage'),
            },
            {
                path: 'register',
                name: 'register',
                component: () => import('@/pages/registerPage'),
            },
            {
                path: 'memberCredit',
                name: 'memberCredit',
                meta: {
                    showBreadCrumbs: true,
                },
                component: () => import('@/pages/memberCreditPage'),
            },
            {
                path: 'giftCard',
                name: 'giftCard',
                meta: {
                    showBreadCrumbs: true,
                },
                component: () => import('@/pages/giftCardPage'),
            },
            {
                path: 'prePayCard',
                name: 'prePayCard',
                meta: {
                    showBreadCrumbs: true,
                },
                component: () => import('@/pages/prePayCardPage'),
            },
            {
                path: 'productList',
                name: 'productList',
                meta: {
                    showBreadCrumbs: true,
                },
                component: () => import('@/pages/productListPage'),
            },
            {
                path: 'productDetail',
                name: 'productDetail',
                meta: {
                    showBreadCrumbs: true,
                },
                component: () => import('@/pages/productDetailPage'),
            },
            {
                path: 'cartDetail',
                name: 'cartDetail',
                component: () => import('@/pages/cartDetailPage'),
            },
            {
                path: 'payDetail',
                name: 'payDetail',
                component: () => import('@/pages/payDetailPage'),
            },
            {
                path: 'aboutUs',
                name: 'aboutUs',
                component: () => import('@/pages/aboutUsPage'),
            },
            {
                path: 'privacy',
                name: 'privacy',
                component: () => import('@/pages/privacyPage'),
            },
            {
                path: 'clause',
                name: 'clause',
                component: () => import('@/pages/clausePage'),
            },            {
                path: 'shop',
                name: 'shop',
                component: () => import('@/pages/shopPage'),
            },
            {
                path: 'account',
                name: 'account',
                component: () => import('@/pages/accountPage'),
            },
            {
                path: 'reload',
                name: 'reload',
                component: () => import('@/pages/reloadPage'),
            }
        ]
    },
]

const router = new Router({
    routes: constantRoutes
})

export default router
