export default class RespCode {
}
RespCode.Success = '00000'
RespCode.ParamError = '00001'
RespCode.ConcurrentError = '00002'
RespCode.DataExist = '00003"'
RespCode.DataNotExist = '00004'
RespCode.PayFail = '00100'
RespCode.PayWaitResult = '00101'
RespCode.LoginStatusError = '00200'
RespCode.SessionInvalid = '00207'
RespCode.NoJoin = "00500"
RespCode.NoRanking = "00501"
RespCode.DataBaseError = '00800'
RespCode.DubboError = '00900'
RespCode.CallOftenError = "00901"
RespCode.CallTooManyError = "00902"
RespCode.IOError = '00994'
RespCode.InterruptError = '00995'
RespCode.InvokeTimeOut = '00996'
RespCode.SystemError = '00997'
RespCode.InvokeResponseError = '00998'
RespCode.UnknownError = '00999'
