import http from './http'

export default {
    register(loginName, loginPassword) {
        const param = {
            goodSource: 'front-pay-apg',
            loginName,
            loginPassword,
            skipGeetest: true,
            registerOrLogin: true
        }
        return http.post('pbMall/userLogin', '', param)
    },

    login(loginName, loginPassword) {
        const param = {
            goodSource: 'front-pay-apg',
            loginName,
            loginPassword,
            skipGeetest: true,
            registerOrLogin: false
        }
        return http.post('pbMall/userLogin', '', param)
    },
    saveUserAddress() {
    },
    queryGoodList(productName) {
        const param = {
            goodSource: 'APG-Mall',
            pageNo: 1,
            itemPerPage: 999,
            goodNameLike: productName
        }
        return http.post('pbMall/queryGoodList', '', param)
    },
    searchGood(productName) {
        const param = {
            goodSource: 'APG-Mall',
            pageNo: 1,
            itemPerPage: 5,
            goodNameLike: productName
        }
        return http.post('pbMall/queryGoodList', '', param)
    }
}