import Vue from 'vue'
import App from './App.vue'
import router from './router'
import util from '@/util/util'

Vue.config.productionTip = false

function defaultErrorHandler(error) {
  console.log(error)
  util.showTipsAuto('系统异常')
}

window.onunhandledrejection = defaultErrorHandler
Vue.config.errorHandler = defaultErrorHandler

const vue = new Vue({
  router,
  render: h => h(App),
})

vue.$mount('#app')

export default vue
