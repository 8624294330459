import cache from "./cache"
import { Loading } from 'element-ui';
import { Message } from 'element-ui';

const eventMap = {}

window.addEventListener('storage', function (e) {
    if (eventMap[e.key]) {
        eventMap[e.key](JSON.parse(e.newValue))
    }
});

function leftPad0(num, len) {
    if (!len) {
        len = 2
    }
    return (num + '').padStart(len, "0")
}

function fireEvent(event, data) {
    if (!data) {
        data = {}
    }
    data.eventTime = +(new Date)
    window.localStorage.setItem(event + '-event-msg', JSON.stringify(data));
}

export default {
    leftPad0,
    getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return null;
    },
    startLoading() {
        return Loading.service();
    },
    stopLoading(loading) {
        loading.close();
    },
    showTipsAuto(text, icon, duration) {
        if (!icon) {
            icon = -1
        }
        if (!duration) {
            duration = 1500
        }
        Message({ message: text, type: icon, duration })
    },
    fireEvent,

    onEvent(event, resolve) {
        eventMap[event + '-event-msg'] = resolve
    },

    onceEvent(event, resolve) {
        eventMap[event + '-event-msg'] = (data) => {
            eventMap[event + '-event-msg'] = ''
            resolve(data)
        }
    },

    offEvent(event) {
        eventMap[event + '-event-msg'] = ''
    },
    prettyDate(dest) {
        const now = new Date()
        const time = new Date().getTime()
        const destTime = dest.getTime()
        if (time - destTime < 60 * 1000) {
            //1分钟内
            return parseInt((time - destTime) / 1000) + "秒钟前"
        } else if (time - destTime < 30 * 60 * 1000) {
            //30分钟内
            return parseInt((time - destTime) / 1000 / 60) + "分钟前"
        } else if (now.getDate() == dest.getDate()) {
            //同一天
            return leftPad0(dest.getHours()) + ":" + leftPad0(dest.getMinutes()) + " 今天"
        } else if (now.getFullYear() == dest.getFullYear()) {
            //同一年
            return (dest.getMonth() + 1) + "月" + dest.getDate() + "日" + leftPad0(dest.getHours()) + ":" + leftPad0(dest.getMinutes())
        } else {
            return dest.getFullYear() + "-" + (dest.getMonth() + 1) + "-" + dest.getDate() + " " + leftPad0(dest.getHours()) +
                ":" + leftPad0(dest.getMinutes())
        }
    },
    fullDate(dest) {
        return dest.getFullYear() + "-" + leftPad0(dest.getMonth() + 1) + "-" + leftPad0(dest.getDate()) + " " + leftPad0(dest.getHours()) +
            ":" + leftPad0(dest.getMinutes())
    },
    fullDateSS(dest) {
        return dest.getFullYear() + "-" + leftPad0(dest.getMonth() + 1) + "-" + leftPad0(dest.getDate()) + " " + leftPad0(dest.getHours()) +
            ":" + leftPad0(dest.getMinutes()) + ":" + leftPad0(dest.getSeconds())
    },
    prettySecond(second) {
        if (second < 60) {
            return second + '秒'
        } else if (second < 3600) {
            return second / 60 + '分钟'
        } else {
            return second / 3600 + '小时'
        }
    },
    getMenuAuthFlag(menuId) {
        const loginData = cache.get('loginData', true);
        let authFlag = '';
        loginData.currentRoleMenuInfoList.forEach(item => {
            if (item.menuInfo.menuId == menuId) {
                authFlag = item.authFlag;
            }
        })
        if (!authFlag) {
            fireEvent('sessionInvalid')
        }
        return authFlag;
    },
}