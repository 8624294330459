function getStorage(session) {
  return session ? window.sessionStorage:window.localStorage;
}

function isNullOrUndefined(value) {
  return value == null || value === "undefined";
}

export default {
  get(key, session){
    let value = getStorage(session).getItem(key);

    return isNullOrUndefined(value) ? null : JSON.parse(value);
  },
  set(key, value, session){
    getStorage(session).setItem(key, JSON.stringify(value));
  },
  remove(key, session){
    getStorage(session).removeItem(key);
  },
  clear(session){
    getStorage(session).clear();
    this.set("isFirst",'true')
  }
}
