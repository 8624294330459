import axios from 'axios'
import cache from './cache'
import util from './util'
import RespCode from './RespCode'

let imgUrl = "https://static.mser.one/"
let apiUrl = "https://pay.hdate.link/pb/"
// let apiUrl = "http://localhost:10175/pb/"
let downloadUrl = "https://pay.hdate.link/downloadTmp/"
let staticPrefixUrl = "https://pay.hdate.link/"
let staticPrefixPath = "/mnt/monster-pay-boss/"
let staticUploadUrl = "https://pay.hdate.link/upload/"


let instance = axios.create({
    baseURL: apiUrl,
    withCredentials: true
})


function beforeAll(data = {}) {
    data.userId = cache.get('userId', true)
    // data.language = init.i18n.locale
    data.sessionId = cache.get('sessionId', true)
    data.roleId = cache.get('roleId', true)
    data.source = '01'
    data.version = '1.0'
}

export default {
    rawPost(url) {
        return axios.post(url)
    },
    post(url, ifShow, data, sessionOut) {
        beforeAll(data)
        let loadingIndex;
        if (!ifShow) {
            var timer = setTimeout(function () {
                loadingIndex = util.startLoading()
            }, 200)
        }
        return instance.post(url, data).catch(e => {
            console.log('123', e)
            clearTimeout(timer)
            if (loadingIndex) {
                util.stopLoading(loadingIndex);
            }
            return Promise.reject(e)
        }).then((response) => {
            clearTimeout(timer)
            if (loadingIndex) {
                util.stopLoading(loadingIndex);
            }
            if (!response || !response.data || !response.data.respCode) {
                return Promise.reject(response)
            } else {
                const code = response.data.respCode.code
                // const message = response.data.respMessage

                if (code !== RespCode.Success) {
                    if (code === RespCode.SessionInvalid) {
                        if (sessionOut) {
                            sessionOut(code)
                        } else {
                            util.fireEvent('sessionInvalid')
                        }
                    } else {
                        return Promise.reject(response.data)
                    }
                } else {
                    return response.data
                }
            }
        })
    },
    get(url) {
        url = apiUrl + '/' + url;
        return axios.get(url).then(data => {
            return data.data
        })
    },
    imgUrl,
    apiUrl,
    downloadUrl,
    staticPrefixUrl,
    staticPrefixPath,
    staticUploadUrl
}
