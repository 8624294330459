<template>
    <div class="breadCrumbs" v-if="showBreadCrumbs">
        <div class="path">{{pagePath}}</div>
        <div class="back" @click="toHome">{{'< 返回上一页'}}</div>
    </div>
</template>

<script>
export default {
    name: 'breadCrumbs',
    data() {
        return {
            showBreadCrumbs: false,
            pagePath: '',
        }
    },
    created() {
        this.freshRoute()
    },
    methods: {
        toHome() {
            this.$router.go(-1)
        },
        freshRoute() {
            this.showBreadCrumbs = this.$route.meta.showBreadCrumbs
            const params = this.$route.query

            if (this.$route.name === 'memberCredit') {
                this.pagePath = '首页/会员积分'
            } else if (this.$route.name === 'giftCard') {
                this.pagePath = '首页/礼品卡'
            } else if (this.$route.name === 'prePayCard') {
                this.pagePath = '首页/预付卡'
            } else if (this.$route.name === 'productList') {
                this.pagePath = '首页/' + params.pathName + '/' + params.productName
            } else if (this.$route.name === 'productDetail') {
                const product = JSON.parse(sessionStorage.getItem('productDetail'))
                this.pagePath = '首页/' + this.getPathName(product) + '/' + this.getProductName(product) + '/详情'
            }
        },
        getPathName(product) {
            if (product.good_name.startsWith('百度网盘')) {
                return '会员积分'
            } else if (product.good_name.startsWith('滴滴出行')) {
                return '会员积分'
            } else if (product.good_name.startsWith('英国亚马逊')) {
                return '礼品卡'
            } else if (product.good_name.startsWith('中国亚马逊')) {
                return '礼品卡'
            } else if (product.good_name.startsWith('美国iTunes')) {
                return '礼品卡'
            } else if (product.good_name.startsWith('百联欢购卡')) {
                return '预付卡'
            } else if (product.good_name.startsWith('百联OK卡')) {
                return '预付卡'
            }
        },
        getProductName(product) {
            if (product.good_name.startsWith('百度网盘')) {
                return '百度网盘'
            } else if (product.good_name.startsWith('滴滴出行')) {
                return '滴滴出行'
            } else if (product.good_name.startsWith('英国亚马逊')) {
                return '英国亚马逊'
            } else if (product.good_name.startsWith('中国亚马逊')) {
                return '中国亚马逊'
            } else if (product.good_name.startsWith('美国iTunes')) {
                return '美国iTunes'
            } else if (product.good_name.startsWith('百联欢购卡')) {
                return '百联欢购卡'
            } else if (product.good_name.startsWith('百联OK卡')) {
                return '百联OK卡'
            }
        },
    },

    watch: {
        $route() {
            this.freshRoute()
        },
    },
}
</script>

<style lang="scss" scoped>
.breadCrumbs {
    margin: 30px auto;
    width: 1060px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .path {
        font-size: 12px;
        color: #1d1d1d;
        line-height: 14px;
    }

    .back {
        cursor: pointer;
        font-size: 12px;
        color: #1d1d1d;
        line-height: 14px;
    }
}
</style>