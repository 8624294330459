<template>
    <div class="baseLayout">
        <div class="layoutTop">
            <div class="menuRow">
                <div class="menu" @click="toPage('index')">首页</div>
                <div class="menu" @click="toPage('aboutUs')">关于我们</div>
                <div class="menu" @click="showProductDropDown = true">
                    产品
                    <div class="dropDown" v-if="showProductDropDown">
                        <div class="mask" @click.stop="dropDownMaskClick" />
                        <div class="dropDownLevel1" @click.stop="">
                            <div class="dropDownRow"
                                @click.stop="dropDownLevel2Show=1">
                                <div class="label">会员积分</div>
                                <div class="arrow">></div>
                                <div class="dropDownLevel2"
                                    v-if="dropDownLevel2Show === 1">
                                    <div class="label" :key="productName"
                                        v-for="productName in memberCreditLabelList"
                                        @click="toPage('productList', {productName})">
                                        {{productName}}
                                    </div>
                                </div>
                            </div>
                            <div class="dropDownRow"
                                @click.stop="dropDownLevel2Show=2">
                                <div class="label">礼品卡</div>
                                <div class="arrow">></div>
                                <div class="dropDownLevel2"
                                    v-if="dropDownLevel2Show === 2">
                                    <div class="label" :key="productName"
                                        v-for="productName in giftCardLabelList"
                                        @click="toPage('productList', {productName})">
                                        {{productName}}
                                    </div>
                                </div>
                            </div>
                            <div class="dropDownRow"
                                @click.stop="dropDownLevel2Show=3">
                                <div class="label">预付卡</div>
                                <div class="arrow">></div>
                                <div class="dropDownLevel2"
                                    v-if="dropDownLevel2Show === 3">
                                    <div class="label" :key="productName"
                                        v-for="productName in prePayCardLabelList"
                                        @click="toPage('productList', {productName})">
                                        {{productName}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="menu" @click="toPage('shop')">商店</div>
            </div>
            <div class="toolBar">
                <img class="logo" src="../assets/logo.png"
                    @click="toPage('index')" />
                <div class="searchInput">
                    <input placeholder="搜索商品" v-model="searchVal"
                        @input="doSearch" />
                    <div class="searchResult" v-if="searchResult.length > 0">
                        <div v-for="item in searchResult" :key="item.good_id"
                            @click="toDetail(item)">
                            {{item.good_name}}
                        </div>
                    </div>
                </div>
                <div class="recentProduct" @click="showRecentDropDown = true">
                    最近浏览的商品
                    <div class="dropDown" v-if="showRecentDropDown">
                        <div class="mask" @click.stop="dropDownMaskClick" />
                        <div class="drowDownField">
                            <img v-for="recent in recentList" class="re"
                                :key="recent.good_id" @click="toDetail(recent)"
                                :src="'https://pay.hdate.link/upload/' + recent.good_img" />
                        </div>
                    </div>
                </div>
                <div class="profile" @click="showProfileDropDown = true">
                    <img class="icon" src="../assets/slice20.png" />
                    <div class="dropDown" v-if="showProfileDropDown">
                        <div class="mask" @click.stop="dropDownMaskClick" />
                        <div class="drowDownField">
                            <div class="account" @click="toPage('account')">账户
                            </div>
                            <div class="logout" @click="toPage('login')">登出
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="favorite">
                    <img class="icon" src="../assets/slice21.png" />
                </div> -->
                <div class="cart" @click="toPage('cartDetail')">
                    <img class="icon" src="../assets/slice22.png" />
                    <span class="cartSize"
                        v-if="cartSize > 0">{{cartSize}}</span>
                </div>
            </div>
            <div class="catalogueRow">
                <div class="catalogue" @click="toPage('memberCredit')">
                    <img src="../assets/slice6.png" />
                    <div class="label">会员积分</div>
                </div>
                <div class="catalogue" @click="toPage('giftCard')">
                    <img src="../assets/slice17.png" />
                    <div class="label">礼品卡</div>
                </div>
                <div class="catalogue" @click="toPage('prePayCard')">
                    <img src="../assets/slice7.png" />
                    <div class="label">预付卡</div>
                </div>
            </div>
        </div>
        <bread-crumbs />
        <router-view />
        <div class="layoutFoot">
            <div class="publicityRow">
                <div class="publicity">
                    <img src="../assets/1.png" />
                    <div class="label">
                        <span class="title">即时送达</span>
                        <span class="sub">我们的订单</span>
                    </div>
                </div>
                <div class="publicity">
                    <img src="../assets/slice12.png" />
                    <div class="label">
                        <span class="title">快速付款</span>
                        <span class="sub">100%安全付款</span>
                    </div>
                </div>
                <div class="publicity">
                    <img src="../assets/slice13.png" />
                    <div class="label">
                        <span class="title">礼券</span>
                        <span class="sub">现在购买</span>
                    </div>
                </div>
                <div class="publicity">
                    <img src="../assets/slice14.png" />
                    <div class="label">
                        <span class="title">即时支持</span>
                        <span class="sub">电子邮件联系</span>
                    </div>
                </div>
            </div>
            <div class="footInfo">
                <div class="roadMap">
                    <div class="companyInfo">
                        <img class="logo" src="../assets/logo.png" />
                        <div class="companyName">忆度科技服务（上海）有限公司</div>
                        <div class="companyAddr">
                        </div>
                        <div class="email">
                            邮箱:
                            <span>huanyichong@tutanota.com</span>
                        </div>
                    </div>
                    <div class="linkColumn">
                        <div class="linkTitle">公司</div>
                        <div class="link" @click="toPage('index')">主页</div>
                        <div class="link" @click="toPage('aboutUs')">关于我们</div>
                        <div class="link" @click="toPage('shop')">商店</div>
                    </div>
                    <div class="linkColumn">
                        <div class="linkTitle">快速链接</div>
                        <div class="link" @click="toPage('account')">账户</div>
                        <div class="link" @click="toPage('privacy')">
                            隐私和Cookie政策
                        </div>
                        <div class="link" @click="toPage('clause')">条款和条件</div>
                    </div>
                </div>
                <div class="copyRightRow">
                    <div class="copyRight">
                        Copyright © 2021 Reload hero. All Rights Reserved.
                    </div>
                    <img />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vue from '@/main'
import breadCrumbs from './breadCrumbs.vue'
import api from '@/util/api'

let searchTimeout
export default {
    components: { breadCrumbs },
    name: 'baseLayout',
    data() {
        return {
            recentList: [],
            memberCreditLabelList: ['百度网盘', '滴滴出行'],
            giftCardLabelList: ['英国亚马逊', '中国亚马逊', '美国iTunes'],
            prePayCardLabelList: ['百联欢购卡', '百联OK卡'],
            showProductDropDown: false,
            dropDownLevel2Show: 0,
            showRecentDropDown: false,
            showProfileDropDown: false,
            cartSize: 0,
            searchVal: '',
            searchResult: [],
        }
    },
    created() {
        let cartList = JSON.parse(localStorage.getItem('cartList') || '[]')
        this.cartSize = cartList.length
        this.recentList = JSON.parse(sessionStorage.getItem('recentList') || '[]')
        vue.$on('cartUpdate', (e) => {
            this.cartSize = e
        })
        vue.$on('recentUpdate', (e) => {
            this.recentList = e
        })
    },
    methods: {
        dropDownMaskClick() {
            this.showRecentDropDown = false
            this.showProductDropDown = false
            this.showProfileDropDown = false
            this.dropDownLevel2Show = 0
        },
        toPage(path, param) {
            if (this.$route.name != path) {
                if (param) {
                    this.$router.push({ name: path, query: param })
                } else {
                    this.$router.push(path)
                }
            } else {
                if (param) {
                    this.$router.replace({ name: 'reload', params: { name: path, query: param } })
                } else {
                    this.$router.push({ name: 'reload', params: { name: path } })
                }
            }
        },
        searchBlur() {
            this.searchResult = []
            this.searchVal = ''
        },
        toDetail(product) {
            this.searchResult = []
            this.searchVal = ''
            if (!product.good_id) {
                return
            }
            sessionStorage.setItem('productDetail', JSON.stringify(product))
            if (this.$route.name != 'productDetail') {
                this.$router.push({ name: 'productDetail', query: product.query })
            } else {
                this.$router.replace({ name: 'reload', params: { name: 'productDetail', query: product.query } })
            }
        },
        doSearch() {
            if (searchTimeout) {
                clearTimeout(searchTimeout)
            }
            searchTimeout = setTimeout(() => {
                api.searchGood(this.searchVal).then((data) => {
                    this.searchResult = data.resultList
                    if (this.searchResult.length === 0) {
                        this.searchResult.push({ good_name: '没有找到产品。' })
                    }
                })
            }, 500)
        },
    },
}
</script>

<style lang="scss" scoped>
.baseLayout {
    .layoutTop {
        .menuRow {
            padding: 0 120px;
            height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 30px;
            border-bottom: 1px solid #063866;
            .menu {
                cursor: pointer;
                position: relative;
                font-size: 14px;
                color: #5c5c5c;
                line-height: 16px;

                .dropDown {
                    position: absolute;
                    top: 20px;
                    left: 0;
                    z-index: 99;

                    .mask {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }

                    .dropDownLevel1 {
                        position: relative;
                        width: 240px;
                        box-sizing: border-box;
                        background: #ffffff;
                        border: 1px solid #e8e8e8;
                        padding: 26px 24px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        gap: 14px;
                        .dropDownRow {
                            position: relative;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .label {
                                font-size: 14px;
                                font-weight: 500;
                                color: #5c5c5c;
                                line-height: 16px;
                            }
                            .arrow {
                                font-size: 14px;
                                font-weight: 500;
                                color: #5c5c5c;
                                line-height: 16px;
                            }
                            .dropDownLevel2 {
                                position: absolute;
                                top: -10px;
                                left: 220px;
                                width: 240px;
                                background: #ffffff;
                                border: 1px solid #e8e8e8;
                                padding: 32px 26px;
                                box-sizing: border-box;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                .label {
                                    height: 17px;
                                    font-size: 12px;
                                    font-weight: 400;
                                    color: #5c5c5c;
                                    line-height: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .toolBar {
            position: relative;
            // width: 100%;
            padding: 0 120px;
            height: 90px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .logo {
                width: 93px;
                height: 44px;
                margin-right: 206px;
            }
            .searchInput {
                width: 472px;
                height: 48px;
                background: #ffffff;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #dcdcdc;
                display: flex;
                flex-direction: row;
                align-items: center;
                position: relative;
                margin-right: 130px;
                input {
                    box-sizing: border-box;
                    width: 472px;
                    height: 46px;
                    padding: 0 40px 0 12px;
                    border: none;
                    outline: none;
                }
                .searchResult {
                    position: absolute;
                    top: 48px;
                    left: 0;
                    right: 0;
                    background: #ffffff;
                    border: 1px solid #dcdcdc;
                    z-index: 999;

                    div {
                        cursor: pointer;
                        margin-left: 20px;
                        font-size: 12px;
                        color: #1d1d1d;
                        line-height: 36px;
                    }
                }
            }

            .recentProduct {
                cursor: pointer;
                color: #063866;
                line-height: 16px;

                .dropDown {
                    position: absolute;
                    top: 90px;
                    left: 0;
                    right: 0;
                    z-index: 99;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .mask {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }

                    .drowDownField {
                        position: relative;
                        width: 1194px;
                        height: 142px;
                        background: #ffffff;
                        border: 1px solid #e8e8e8;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 0 30px;
                        gap: 22px;

                        img {
                            width: 80px;
                            height: 55px;
                        }
                    }
                }
            }

            .profile {
                cursor: pointer;
                position: relative;
                margin-left: 30px;
                .icon {
                    width: 24px;
                    height: 24px;
                }
                .dropDown {
                    position: absolute;
                    top: 30px;
                    left: 0;
                    z-index: 99;

                    .mask {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }

                    .drowDownField {
                        position: relative;
                        width: 198px;
                        background: #ffffff;
                        border: 1px solid #e8e8e8;
                        box-sizing: border-box;
                        padding: 24px;
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        .account {
                            height: 17px;
                            font-size: 12px;
                            color: #000000;
                            line-height: 14px;
                        }
                        .detail {
                            height: 17px;
                            font-size: 12px;
                            color: #000000;
                            line-height: 14px;
                        }
                        .logout {
                            border-top: 1px solid #e8e8e8;
                            padding-top: 12px;
                            font-size: 12px;
                            color: #000000;
                            line-height: 14px;
                        }
                    }
                }
            }
            .favorite {
                cursor: pointer;
                margin-left: 16px;
                .icon {
                    width: 24px;
                    height: 24px;
                }
            }
            .cart {
                position: relative;
                cursor: pointer;
                margin-left: 16px;
                .icon {
                    width: 24px;
                    height: 24px;
                }
                span {
                    position: absolute;
                    bottom: 5px;
                    right: -5px;
                    width: 16px;
                    height: 16px;
                    background: #063866;
                    border-radius: 50%;
                    font-size: 8px;
                    color: #ffffff;
                    line-height: 16px;
                    text-align: center;
                }
            }
        }
        .catalogueRow {
            background-color: #063866;
            width: 100%;
            height: 60px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            .catalogue {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
                img {
                    width: 33px;
                    height: 33px;
                }
                .label {
                    color: #ffffff;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
    }
    .layoutFoot {
        .publicityRow {
            background-color: #063866;
            width: 100%;
            height: 140px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            .publicity {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 28px;

                img {
                    width: 60px;
                    height: 60px;
                }

                .label {
                    display: flex;
                    flex-direction: column;
                    .title {
                        height: 22px;
                        font-size: 16px;
                        font-weight: 500;
                        color: #ffffff;
                        line-height: 19px;
                    }
                    .sub {
                        height: 20px;
                        font-size: 14px;
                        color: #ffffff;
                        line-height: 16px;
                    }
                }
            }
        }
        .footInfo {
            padding: 0 122px;
            .roadMap {
                padding: 0 150px 0 0;
                height: 256px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .companyInfo {
                    img {
                        width: 93px;
                        height: 44px;
                    }
                    .companyName {
                        margin-top: 30px;
                        height: 20px;
                        font-size: 14px;
                        font-weight: 600;
                        color: #1d1d1d;
                        line-height: 16px;
                    }
                    .companyAddr {
                        font-size: 14px;
                        color: #707070;
                        line-height: 16px;
                    }
                    .email {
                        span {
                            color: #063866;
                        }
                    }
                }
                .linkColumn {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .linkTitle {
                        font-size: 16px;
                        font-weight: 600;
                        color: #1d1d1d;
                        line-height: 19px;
                    }
                    .link {
                        cursor: pointer;
                        font-size: 14px;
                        color: #5c5c5c;
                        line-height: 16px;
                    }
                }
            }
            .copyRightRow {
                padding-top: 15px;
                border-top: 1px solid #d9d9d9;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 40px;
                .copyRight {
                    height: 17px;
                    font-size: 12px;
                    font-weight: 600;
                    color: #707070;
                    line-height: 14px;
                }

                img {
                    width: 200px;
                    height: 15px;
                }
            }
        }
    }
}
</style>
